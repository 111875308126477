<template>
  <div>
    <b-card no-body>
      <template #header>
        <h4 class="mr-auto mb-0">
          {{ tableTitle }}
        </h4>
      </template>
      <div>
        <b-row>
          <b-col
            cols="12"
            lg="4"
          >
            <div class="d-flex px-2 py-1">
              <b-button
                size="sm"
                class="mr-1"
                @click="refreshTable"
              >
                Refresh
              </b-button>
              <b-form-select
                v-model="payloadConfig.perPage"
                class="w-25"
                :options="perPageOptions"
                size="sm"
                @change="updatePerPage"
              />
            </div>
          </b-col>

          <b-col
            cols="12"
            lg="4"
          >
            <div class="d-flex px-2 py-1">

              <flat-pickr
                v-model="tempAdvForm.date_of_record"
                placeholder="Select Date"
                class="form-control form-control-sm"
                :config="{ mode: 'range',
                           maxDate: new Date().fp_incr(1),
                           dateFormat: 'd-m-Y',}"
                @on-change="function(selectedDates , dateStr , instance ) {
                  onDateRangePickerSelected(selectedDates, dateStr, instance, 'date_of_record')
                }"
              />

            </div>
          </b-col>
          <b-col
            cols="12"
            lg="4"
          >
            <div class="px-2 py-1">
              <v-select
                v-model="payloadConfig.q"
                :reduce="logOpts => logOpts.value"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="key"
                class="select-size-sm"
                placeholder="Please select"
                :options="logOpts"
                @option:selected="filterQ($event)"
              />
            </div>
          </b-col>
        </b-row>
        <b-table
          hover
          responsive
          :fields="TableHeader"
          :per-page="payloadConfig.perPage"
          :items="ListOfRegisteredMerchant"
          show-empty
        >
          <template #empty="scope">
            <div class="p-3 text-center">
              No records
            </div>
          </template>
          <template #emptyfiltered="scope">
            <div class="p-3 text-center">
              No records matching filter
            </div>
          </template>
          <template #cell(show_details)="row">
            <b-button
              size="sm"
              variant="link"
              class="p-0"
              @click="row.toggleDetails"
            >

              <template v-if="row.detailsShowing">
                <feather-icon
                  size="24"
                  icon="ChevronDownIcon"
                />
              </template>
              <template v-else>
                <feather-icon
                  size="24"
                  icon="ChevronRightIcon"
                />
              </template>
            </b-button>
          </template>

          <template #cell(action)="row">
            <b-button
              size="sm"
              @click="showRowDataModal(row.item)"
            >
              View
            </b-button>
          </template>

          <template #row-details="row" />
          <template #cell(date_of_record)="row">
            {{ transformDate(row.item.date_of_record, false, 'FF' ) }}
          </template>
        </b-table>

        <!--    <pre>{{ ListOfRegisteredMerchant }}</pre>-->

        <div class="d-flex justify-content-between align-items-center border-top p-2">
          <div>
            Total Records: {{ ListOfRegisteredMerchantMeta.recordsTotal }}
          </div>
          <b-pagination
            v-model="payloadConfig.currentPage"
            first-number
            last-number
            :disabled="ListOfRegisteredMerchantMeta.recordsTotal < payloadConfig.perPage"
            :total-rows="ListOfRegisteredMerchantMeta.recordsTotal"
            :per-page="payloadConfig.perPage"
            @change="pagination($event)"
          />
        </div>
      </div></b-card>
    <b-modal
      ref="rowDataViewerRef"
      size="xl"
      header-bg-variant="primary"
      header-text-variant="light"
      title="Raw Data"
      hide-header
      no-close-on-esc
      no-close-on-backdrop
    >
      <json-viewer
        :value="selectedRowData"
        :expand-depth="5"
        copyable
        boxed
        sort
      />
    </b-modal>
  </div>

</template>

<script>
import JsonViewer from 'vue-json-viewer'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import onboardDetailView from '@/views/administration/onboardingv2/components/OnboardDetailView.vue'
import humaniseMixin from '@/common/humanise.mixin'
import OnboardNewMerchantForm from '@/views/administration/onboardingv2/components/OnboardNewMerchantForm.vue'

export default {
  components: {
    OnboardNewMerchantForm,
    onboardDetailView,
    JsonViewer,
    flatPickr,
    vSelect,
  },
  mixins: [humaniseMixin],
  data() {
    return {
      tableTitle: 'Service Audit',
      dtForm: {
        date_of_record: {
          start: '',
          end: '',
        },
      },
      tempAdvForm: {
        date_of_record: '',
      },

      rangeDate: null,
      rejectForm: {
        reason: '',
        rejected_onboarding_checklists: [],
      },
      selectedRowData: {},
      rejectSection: 'details',
      payloadConfig: {
        perPage: 50,
        length: 50,
        currentPage: 1,
        searchTerm: '',
        q: null,
        start: 0,
      },
    }
  },
  computed: {
    TableHeader() {
      const x = [
        {
          key: 'show_details',
          label: '',
          sortable: false,
          visible: false,
        },
        {
          key: 'log_tag', label: 'Log Tag', sortable: false, visible: true,
        },
        {
          key: 'date_of_record', label: 'Record Date', sortable: false, visible: true,
        },
        {
          key: 'action',
          label: 'action',
          class: 'text-right',
          sortable: false,
          visible: true,
        },
      ]

      return x.filter(y => y.visible)
    },

    perPageOptions() {
      return [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ]
    },
    logOpts() {
      const opts = this.$store.getters['onboardingv2/getOnboardConstantsByKey']('ResponseLoggerFolderPrefix')

      if (Array.isArray(opts)) {
        return opts
      } return []
    },

    ListOfRegisteredMerchant() {
      return this.$store.getters['onboardingv2/getServiceAuditList']
    },
    ListOfRegisteredMerchantMeta() {
      const list = this.$store.getters['onboardingv2/getServiceAuditListMeta']

      if (list) {
        const picked = (({ a, c }) => ({ a, c }))(list)
        return list
      }
      return {}
    },
  },
  async mounted() {
    const breadcrumbUpdatePayload = [
      { title: 'Service Audit' },
    ]
    await this.$store.dispatch('systemConstants/getSystemConstants')
    await this.$store.dispatch('onboardingv2/getOnboardConstants')
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
    this.payloadConfig.q = 'Log/MAMCreateUser'
    await this.updateTable(this.payloadConfig)
  },
  methods: {
    async filterQ(q) {
      this.payloadConfig.q = q.value

      await this.updateTable(this.payloadConfig)
    },
    async pagination(e) {
      console.log(e)
      this.payloadConfig.start = (e - 1) * this.payloadConfig.perPage
      await this.updateTable(this.payloadConfig)
    },

    onDateRangePickerSelected(selectedDates, dateStr, instance, fieldName) {
      if (selectedDates.length === 2) {
        instance.formatDate(selectedDates[0], 'Y-m-d')

        this.dtForm[fieldName].start = instance.formatDate(selectedDates[0], 'Y-m-d')
        this.dtForm[fieldName].end = instance.formatDate(selectedDates[1], 'Y-m-d')

        this.updateTable(this.payloadConfig)
      }
    },

    async refreshTable() {
      this.payloadConfig.start = 0
      this.payloadConfig.currentPage = 1
      await this.updateTable(this.payloadConfig)
    },
    async updatePerPage(perPageVal) {
      this.payloadConfig.length = perPageVal

      this.payloadConfig.start = 0
      this.payloadConfig.currentPage = 1

      console.log(this.payloadConfig)
      await this.updateTable(this.payloadConfig)
    },
    async updateTable(config) {
      const filterValue = [

      ]

      if (this.dtForm.date_of_record.start !== '') {
        const item = {
          filter_column: 'date_of_record',
          filter_start: this.dtForm.date_of_record.start,
          filter_end: this.dtForm.date_of_record.end,
        }

        filterValue.push(item)
      }

      if (this.payloadConfig.q) {
        const item = {
          filter_column: 'q',
          filter_value: this.payloadConfig.q,
        }

        filterValue.push(item)
      }

      if (this.payloadConfig.searchTerm) {
        const item = {
          filter_column: 'keyword',
          filter_value: this.payloadConfig.searchTerm,
        }

        filterValue.push(item)
      }

      const payload = {
        length: config.length,
        start: config.start,
        perPage: config.perPage,
        filter_array_objects: JSON.stringify(filterValue),
      }

      await this.$store.dispatch('onboardingv2/getServiceAuditList', payload)
    },
    showRowDataModal(data) {
      this.selectedRowData = data
      this.$refs.rowDataViewerRef.show()
    },
  },
}
</script>

<style lang="scss">

.rejection-reason-checkbox {
  .custom-control.custom-checkbox {
    width: 100%;
  }
}

</style>
